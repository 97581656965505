/* global zE */

import axios from "axios"

import { localStorageGetItem, localStorageSetItem } from "./utilities/storage"

// custom typefaces
import "fontsource-poppins/400.css"
import "fontsource-poppins/500-normal.css"
import "fontsource-poppins/600-normal.css"
import "fontsource-poppins/800-normal.css"

// global CSS styles
import "./src/styles/index.scss"

const getClientData = () => {
	const request = {
		method: "GET",
		url: 'https://cms.checkthatproperty.com.au/api',
		params: {
			action: "client_data",
		},
		responseType: "json",
	}

	axios(request)
		.then(response => {
			if (response.data.success) {
				localStorageSetItem("client", JSON.stringify(response.data.data))
			}
		})
		.catch(error => alert(JSON.stringify(error.response, null, 4)))
}

const getApiStatus = () => {
	const request = {
		method: "GET",
		url: 'https://cms.checkthatproperty.com.au/api',
		params: {
			action: "api_status",
		},
		responseType: "json",
	}

	axios(request)
		.then(response => {
			if (response.data.success) {
				const elem = document.getElementById('system-status')

				if (elem && 'maintenance' === response.data.data.operation) {
					const container = elem.querySelector('.container')

					container.innerHTML = response.data.data.message
					elem.style.display = 'block'
				}
			}
		})
		.catch(error => alert(JSON.stringify(error.response, null, 4)))
}

export const onInitialClientRender = () => {
	if (window.self !== window.top) {
		return
	}

	console.log("INIT!")

	if (!localStorageGetItem("client")) {
		getClientData()
	}

	document.addEventListener("click", event => {
		if (event.target.classList.contains("ze-opener")) {
			zE("webWidget", "open")
		}
	})

	const script = document.createElement("script")
	script.defer = true
	script.src = "https://static.zdassets.com/ekr/snippet.js?key=90e3e145-079d-4cba-bc3d-811befcb1669"
	script.id = "ze-snippet"
	document.body.appendChild(script)
}

export const onRouteUpdate = ({ location }) => {
	if (window.self !== window.top) {
		return
	}

	console.log("READY!", location.pathname)

	getApiStatus()
	setTimeout(() => {
		const hash = window.location.hash

		if (hash) {
			const elem = document.getElementById(hash.split("#").pop())

			if (elem) {
				elem.scrollIntoView()
			}
		}
	}, 350)
}

export const onServiceWorkerUpdateReady = () => {
	const answer = window.confirm(`This application has been updated. Reload to display the latest version?`)

	if (answer === true) {
		window.location.reload()
	}
}
